import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getCompanyRewardsDocuments,
  clearCompanyRewardsDocuments
} from '../../redux/actions';
import { getCompanyRewards } from '../../redux/actions/peopleHomeActions';
import { isEmpty, isNull, isUndefined } from "lodash";
import {
  BodyTable,
  Circle,
  Layout,
  RewardTablesContainer,
  Tick,
  TickMark
} from "../../../src/containers/companyDetails/newCompanyDetailsStyles";
import Waiting from '../Waiting';
import { withTranslation } from 'react-i18next';
import { convertMonthFromDate } from '../../utils/methods';

class NewCompanyDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstIndex: 0,
      secondIndex: 0,
      showPDFModal: false,
      pdfBase64: '',
      isDownload: false,
      onHover: -1,
    }
  }

  componentDidMount() {
    const { userId, getCompanyRewards } = this.props
    getCompanyRewards(userId)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.companyRewardsDocuments !== this.props.companyRewardsDocuments) {
      if (!isEmpty(this.props.companyRewardsDocuments)) {
        this.setState({
          pdfBase64: this.props.companyRewardsDocuments.reward_document
        });
        if (this.state.isDownload) {
          this.downloadFile(this.props.companyRewardsDocuments.reward_document);
        } else {
          this.setState({ showPDFModal: true });
        }
      }
    }
  }

  onOpenPdf(documentId) {
    this.setState({
      isDownload: false
    });
    const { getCompanyRewardsDocuments } = this.props;
    getCompanyRewardsDocuments(documentId);
  }

  onFetchDocumentById(documentId) {
    const { getCompanyRewardsDocuments } = this.props;
    getCompanyRewardsDocuments(documentId);
    this.setState({
      isDownload: true
    });
  }

  downloadFile(ImageBase64) {
    const downloadLink = document.createElement("a");
    downloadLink.download = "company_reward_document.pdf";
    downloadLink.target = '_blank';
    downloadLink.href = ImageBase64;
    downloadLink.click();
  }

  removePDF = () => {
    this.setState({
      showPDFModal: false,
      pdfBase64: '',
    })
  };

  closePopup = () => {
    this.setState({
      showPDFModal: false
    })
  }


  onEnterMouse = (id) => {
    this.setState({
      onHover: id
    })
  };

  onLeaveMouse = () => {
    this.setState({
      onHover: -1
    })
  };

  render() {
    const { companyRewards } = this.props;
    const lang = localStorage.getItem("lang");

    if(isNull(companyRewards) || isUndefined(companyRewards) || isEmpty(companyRewards)){
      return <Waiting/>
    }
    return (
      <div>
        <Layout>
          <RewardTablesContainer style={{padding: "1px"}}>
            {companyRewards.company_programs &&
            companyRewards.company_programs.length ? (
                companyRewards.company_programs.map(
                  (companyRewardData, index) => (
                    <BodyTable
                      key={index}
                      bgcolorOne={companyRewardData.id == "1" ? "rgba(0, 47, 71, 0.9)" : null}
                      isCompanyRewards={companyRewardData.id == "1" ? true : false}
                      height={
                        index === 0
                          ? "322px"
                          : companyRewardData.reward_name === "My Company Rewards"
                            ? "322px"
                            : companyRewardData.reward_name ===
                              "Medical Premium Reduction"
                              ? "236px"
                              : "322px"
                      }
                    >
                      <div className={"tableHeader"} >
                        <div className={"headerLeft"}>
                          <span>{this.props.t(companyRewardData.reward_name)}</span>
                        </div>
                        {companyRewardData?.show_total_prize ? <div>
                          {this.props.t("Total Prize value")}: {companyRewardData?.total_prize}
                        </div>: ''}
                      </div>
                      {companyRewardData.program_levels.map(
                        (rowData, rowIndex) => (
                          <div className={"tableContentContainer"} key={rowIndex}>
                            <div className="subContainer">
                              <div className={"tableColumnHeader"}>
                                <div className={"tableColumn"}>{this.props.t("Level")}</div>
                                <div className={"tableColumn"}>{this.props.t("My Points")}</div>
                                <div className={"tableColumn"}>{this.props.t("Point Goal")}</div>
                                <div className={"tableColumn flex-basis-30"}>
                                  {this.props.t("Prize")}
                                </div>
                                <div className={"tableColumn"}>{this.props.t("Deadline")}</div>
                                <div className={"tableColumn"}>{this.props.t("Achieved")}</div>
                              </div>
       
                              <div className={"tableRowContainer"} key={rowIndex}>
                                <div className={"tableRow"}>
                                  {rowData.level_name ? this.props.t(rowData.level_name) : "-"}
                                </div>
                                <div className={"tableRow"}>
                                  {rowData.user_points
                                    ? rowData.user_points
                                    : "-"}
                                </div>
                                <div className={"tableRow"}>
                                  {rowData.point ? rowData.point : "-"}
                                </div>
                                <div className={"tableRow flex-basis-30"}>
                                  {rowData.prize ? rowData.prize : "-"}
                                </div>
                                <div className={"tableRow"}>
                                  {rowData.end_date ? ( (lang !== 'fr') ? (convertMonthFromDate(rowData.end_date, this.props)) : (convertMonthFromDate(rowData.end_date, this.props).replace(/,/g,"")) ) : "-"}
                                </div>
                                <div className={"tableRow"}>
                                  {rowData.achieved_date
                                    ? convertMonthFromDate(rowData.achieved_date, this.props)
                                    : "-"}
                                </div>
                              </div>
                            </div>
                            <div className="background_container">
                              <TickMark
                                bgColor={
                                  rowData.check_status === 1
                                    ? "#9FC989"
                                    : "rgba(156, 156, 156, 0.4)"
                                }
                              >
                                <Circle>
                                  <Tick
                                    checked={
                                      rowData.check_status === 1
                                        ? "rgba(159, 201, 137, 1)"
                                        : "rgba(156, 156, 156, 1)"
                                    }
                                  />
                                </Circle>
                              </TickMark>
                            </div>
                          </div>
                        )
                      )}
                    </BodyTable>
                  )
                )
              ) : (
                <div className={"no-progress"} style={{ height: "350px", position: "relative", backgroundColor: 'unset',justifyContent:"flex-start", alignItems:"flex-start", width:"100%" }}>
                  {" "}
                  {this.props.t("No Company Programs Available")}
                </div>
              )}
          </RewardTablesContainer>
        </Layout>
      </div>
    );
  }}

NewCompanyDetails.propTypes = {
  reward: PropTypes.object,
  getCompanyRewards: PropTypes.func,
  getCompanyRewardsDocuments: PropTypes.func,
  companyRewards: PropTypes.array,
  companyRewardsDocuments: PropTypes.array,
  clearCompanyRewardsDocuments: PropTypes.func,
  userId: PropTypes.number,
  t: PropTypes.func
};

const mapStateToProps = (state) => ({
  companyRewards: state.wellnessDashboard.companyRewards,
  companyRewardsDocuments: state.wellnessDashboard.companyRewardsDocuments,
});

const mapDispatchToProps = (dispatch) => ({
  getCompanyRewards: (userId) => dispatch(getCompanyRewards(userId)),
  getCompanyRewardsDocuments: (id) => dispatch(getCompanyRewardsDocuments(id)),
  clearCompanyRewardsDocuments: () => dispatch(clearCompanyRewardsDocuments())
});

export default connect(mapStateToProps, mapDispatchToProps)((withTranslation())(NewCompanyDetails));
